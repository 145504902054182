.circle-box {
  width: 12px;
  height: 12px;
  display: flex; /* 자식 요소 정렬용 */
  align-items: center; /* 수직 정렬 */
  justify-content: center; /* 수평 정렬 */
}
.circle-box-item-01 {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.circle-box-item-01:hover {
  transform: scale(1.3); /* 클릭 범위 늘이기 */
}
.circle-box-item-02 {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.circle-box-hoverimage {
  position: absolute;
  top: -575%;
  left: 140%;
  max-height: 80px;
  z-index: 1004;
}
