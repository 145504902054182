.heart-box {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20%;
}
.heart-box:hover {
  transform: scale(1.3);
}
.heart-box-item {
  padding: 1px;
  width: 12px;
  height: 11px;
  border-radius: 20%;
}
