.organized-box {
  z-index: 1003;
  position: fixed;
  display: flex;
  align-items: center;
  left: 40px;
  color: #000000;
  font-size: 1.25rem;
  font-family: 'Proximanova Regular';
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
  pointer-events: auto;
}
.organized-box-text {
  padding-right: 16px;
}
