.topsheet-container {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 16.71%,
    rgba(255, 255, 255, 0.85) 45.07%,
    #fff 83.34%
  );
  transform: translateY(-100%);
  transition: transform 0.6s ease-out;
  z-index: 1002;
}
.topsheet-container.open {
  transform: translateY(0%); /* 최상단 상태 */
}

.topsheet-text-title {
  color: #000000;
  font-size: 1.25rem;
  font-family: 'SM JGothic 10';
  line-height: 30px;
  text-align: center;
  padding-top: 40px;
}
.topsheet-text-content {
  color: #000000;
  font-size: 1rem;
  font-family: 'SM JGothic 10';
  line-height: 30px;
  text-align: center;
}
