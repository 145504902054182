.bottomsheet-container {
  position: fixed;
  pointer-events: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 250px);
  transform: translateY(100%);
  transition: transform 0.5s ease-out;
  z-index: 1001;
}
.bottomsheet-container.mid {
  transform: translateY(calc(100% - 250px)); /* 중간 상태 */
}
.bottomsheet-container.open {
  transform: translateY(0%); /* 최상단 상태 */
}

.bottomsheet-background-01 {
  z-index: 1002;
  height: 250px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 42%,
    rgba(255, 255, 255, 0.85) 64.5%,
    rgba(255, 255, 255, 0.85) 100%
  );
}
.bottomsheet-background-02 {
  z-index: 1002;
  height: 100vh;
  position: relative;
  background: rgba(255, 255, 255, 0.85);
}
.bottomsheet-content {
  z-index: 1002;
  position: absolute;
  background: transparent;
  scrollbar-width: none;
  pointer-events: auto;
}

.bottomsheet-content-box {
  display: flex;
  align-items: end;
  padding-left: 226px;
}
.bottomsheet-content-box-text {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
}
.bottomsheet-content-box-number {
  width: 30px;
}
.bottomsheet-content-box-image {
  max-width: 924px;
  max-height: 144px;
  margin-left: 48px;
}
.bottomsheet-content-box-icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #000000;
  margin-right: 16px;
  cursor: pointer;
  pointer-events: auto;
}
