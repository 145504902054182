.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  user-select: none; /* 드래그 방지 */
  -webkit-tap-highlight-color: transparent;
}

.titleBox {
  z-index: 999;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  text-align: -webkit-center;
  color: #000;
}

.title {
  width: max-content;
  font-size: 1.625rem;
  font-family: 'Lato Medium';
  line-height: 20px; /* 76.923% */
  cursor: pointer;
}

.subtitle {
  margin-top: 12px;
  font-size: 1.1875rem;
  font-family: 'Lato Regular';
  line-height: 19px;
  cursor: none;
}

.container {
  position: relative;
  width: calc(1366px * 2);
  height: calc(1024px * 2);
  /* background-color: #e9e9e9; */
}
