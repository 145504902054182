.loading-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40px;
}

.loading-dot {
  width: 7px;
  height: 7px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 1);
  animation: disappear 1.2s infinite;
}

.loading-dot:nth-child(1) {
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.4s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.8s;
}

@keyframes disappear {
  0%,
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
