html {
  width: 100vw;
  height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  font-size: 16px; /* 1rem = 16px */
  cursor: url('/src/assets/icons/onHeart.svg'), auto;
}
